<template>
  <div class="last-vs-games">
    <div class="last-vs-games__header">
      Enfrentamientos previos entre ellos (últimos 5 juegos)
    </div>
    <div class="last-vs-games__icons">
      <div class="last-vs-games__icons__ball">
        <img src="/assets/icons/icn-goles@2x.png" alt="" />
      </div>
      <div class="last-vs-games__icons__team">
        <img src="https://az755631.vo.msecnd.net/teams-80/257.png" alt="" />
      </div>
      <div class="last-vs-games__icons__result green">
        <p>G</p>
      </div>
      <div class="last-vs-games__icons__result grey">
        <p>E</p>
      </div>
      <div class="last-vs-games__icons__result red">
        <p>P</p>
      </div>
      <div class="last-vs-games__icons__team">
        <img src="https://az755631.vo.msecnd.net/teams-80/260.png" alt="" />
      </div>
      <div class="last-vs-games__icons__ball">
        <img src="/assets/icons/icn-goles@2x.png" alt="" />
      </div>
    </div>
    <div class="last-vs-games__percentages">
      <div>
        <p>11</p>
      </div>
      <div></div>
      <div>
        <p>40%</p>
      </div>
      <div>
        <p>20%</p>
      </div>
      <div>
        <p>40%</p>
      </div>
      <div></div>
      <div>
        <p>9</p>
      </div>
    </div>
    <div class="last-vs-games__fractions">
      <div></div>
      <div></div>
      <div>
        <p>2/5</p>
      </div>
      <div>
        <p>1/5</p>
      </div>
      <div>
        <p>2/5</p>
      </div>
      <div></div>
      <div></div>
    </div>
    <div v-for="(game, index) in games" class="last-vs-games__by-date" :key="index">
      <div class="date">
        <p>{{ game.date }}</p>
      </div>
      <div class="team">
        <p>{{ game.homeTeam }}</p>
      </div>
      <div class="score">
        <p>{{ game.score }}</p>
      </div>
      <div class="team">
        <p>{{ game.awayTeam }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LastVSGames',
  data() {
    return {
      games: [
        {
          date: '01-04-2021',
          homeTeam: 'Manchester',
          awayTeam: 'Brentford',
          score: '1:0',
        },
        {
          date: '01-04-2021',
          homeTeam: 'Brentford',
          awayTeam: 'Manchester',
          score: '3:2',
        },
        {
          date: '01-04-2021',
          homeTeam: 'Manchester',
          awayTeam: 'Brentford',
          score: '5:0',
        },
        {
          date: '01-04-2021',
          homeTeam: 'Manchester',
          awayTeam: 'Brentford',
          score: '3:3',
        },
        {
          date: '01-04-2021',
          homeTeam: 'Brentford',
          awayTeam: 'Manchester',
          score: '3:0',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
$titleBackground: #e6e5e6;
$byDateFontSize: 0.85rem;
$black29: #292929;

@mixin mainGrid {
  display: grid;
  grid-template-columns: 10% 25% 10% 10% 10% 25% 10%;
}

.last-vs-games {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    border-radius: 4.7px;
    background-color: $titleBackground;
    padding: 5px 0;
  }

  &__icons {
    @include mainGrid;
    margin-top: 4px;
    background-color: $titleBackground;
    padding: 6px 0;
    border-top-left-radius: 4.7px;
    border-top-right-radius: 4.7px;

    & > div {
      align-self: center;
      justify-self: center;
    }

    &__result {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        margin-top: 2px;
      }

      &.red {
        background-color: #eb2524;
      }

      &.green {
        background-color: #73d44e;
      }

      &.grey {
        background-color: #ababab;
      }
    }

    &__ball {
      width: 23px;
      height: 23px;
    }

    &__team {
      height: 30px;
      width: 30px;
    }
  }

  &__percentages {
    @include mainGrid;
    background-color: #eeeeee;
    color: #3f3e3e;
    font-size: 0.75rem;

    & > div {
      align-self: center;
      justify-self: center;
      padding: 0.25rem 0;
    }
  }

  &__fractions {
    @include mainGrid;
    background-color: #f4f4f4;
    color: #3f3e3e;
    font-size: 0.75rem;
    border-bottom-left-radius: 4.7px;
    border-bottom-right-radius: 4.7px;
    margin-bottom: 0.65rem;

    & > div {
      align-self: center;
      justify-self: center;
      padding: 0.25rem 0;
    }
  }

  &__by-date {
    display: grid;
    grid-template-columns: 35% 20% 12% 1fr;
    font-size: $byDateFontSize;

    & > .date {
      font-family: Avenir-Regular, sans-serif;
    }

    & > .team {
      justify-self: left;
      text-align: left;
      color: $black29;
    }

    & > .score {
      color: $black29;
    }
  }
}
</style>
