<template>
  <div class="right-bet">
    <div class="right-bet__header header">
      <h6 class="header__title">
        {{ this.hasMainLeagueGames ? tournamentName : mainLeague.provisional_season.name }}
      </h6>
      <div class="header__selects">
        <BField custom-class="text-labels" label="JORNADA">
          <BSelect v-model="currentTournament" @input="selectDefaultMatch()" placeholder="-">
            <option v-for="(option, i) in Object.keys(fullTournaments)" :value="option" :key="i">
              {{ option }}
            </option>
          </BSelect>
        </BField>
        <BField custom-class="text-labels" label="PARTIDO">
          <BSelect v-model="currentMatch" placeholder="-">
            <option v-for="(option, i) in gameOptions" :value="option" :key="i">
              {{ option.label }}
            </option>
          </BSelect>
        </BField>
      </div>
    </div>
    <div class="right-bet__spacer" />
    <template v-if="currentMatch">
      <div class="right-bet__teams">
        <div class="teams">
          <img class="teams__img" :src="getTeamLogo(currentMatch, 'home')" alt="chivas" />
          <div class="teams__name">
            {{ currentMatch.home_team.acronym }}
          </div>
          <div class="teams__vs">VS</div>
          <div class="teams__name">
            {{ currentMatch.visiting_team.acronym }}
          </div>
          <img class="teams__img" :src="getTeamLogo(currentMatch, 'away')" alt="chivas" />
        </div>
        <div class="date">
          <span class="date__journey">J{{ currentTournament }}</span>
          <span>{{ currentMatch.displayDate }}</span>
        </div>
      </div>
      <div class="right-bet__info">
        <div class="right-bet__info__last-games-container" v-show="areTablesVisible">
          <div class="last-games">
            <p class="last-games__title">Resultado últimos 5 juegos</p>
            <div class="last-games__header">
              <p />
              <p />
              <p>PTS.</p>
              <p>#</p>
            </div>
            <div class="last-games__rows">
              <img :src="getTeamLogo(currentMatch, 'home')" :alt="currentMatch.home_team.acronym" />
              <div v-if="homeLastFiveGames.lastGames && homeLastFiveGames.lastGames.length" class="team-row">
                <div
                  v-for="(item, i) in homeLastFiveGames.lastGames"
                  :key="i"
                  class="team-row__item"
                  :class="item === 'G' ? 'win' : item === 'E' ? 'draw' : 'lose'"
                >
                  <span>{{ item }}</span>
                </div>
              </div>
              <div v-else>
                Sin partidos jugados
              </div>
              <p class="team-row__pts">
                {{ homeLastFiveGames.points }}
              </p>
              <p class="team-row__pos">
                {{ homeLastFiveGames.pos }}
              </p>
            </div>
            <div class="last-games__rows">
              <img :src="getTeamLogo(currentMatch, 'away')" :alt="currentMatch.visiting_team.acronym" />
              <div v-if="visitingLastFiveGames.lastGames && visitingLastFiveGames.lastGames.length" class="team-row">
                <div
                  v-for="(item, i) in visitingLastFiveGames.lastGames"
                  :key="i"
                  class="team-row__item"
                  :class="item === 'G' ? 'win' : item === 'E' ? 'draw' : 'lose'"
                >
                  <span>{{ item }}</span>
                </div>
              </div>
              <div v-else>
                Sin partidos jugados
              </div>
              <p class="team-row__pts">
                {{ visitingLastFiveGames.points }}
              </p>
              <p class="team-row__pos">
                {{ visitingLastFiveGames.pos }}
              </p>
            </div>
          </div>
          <LastVSGames v-if="false" />
        </div>
        <div class="spacer" />
        <div class="right-bet__info__expand">
          <div class="right-bet__info__expand__text">
            Ver últimos resultados
          </div>
          <div class="right-bet__info__expand__btn" @click="areTablesVisible = !areTablesVisible">
            <img v-if="areTablesVisible" src="/icons/icn_arrow_collapse.svg" alt="" />
            <img v-else src="/icons/icn_arrow_expand.svg" alt="" />
          </div>
        </div>
        <div class="bet-stadistics">
          <div class="bet-stadistics__title">Mayor probabilidad:</div>
          <div class="bet-stadistics__subtitle" :class="{ blurred: !isPremiumUser }">{{ maxProb }}</div>
          <!--          <BField custom-class="text-labels" label="TIPO DE APUESTA">-->
          <!--            <BSelect v-model="betType">-->
          <!--              <option v-for="option in fakeBetTypes" :value="option.id" :key="option.id">-->
          <!--                {{ option.name }}-->
          <!--              </option>-->
          <!--            </BSelect>-->
          <!--          </BField>-->
          <div class="bet-stadistics__container">
            <div class="bet-stadistics__table">
              <span class="headr bold">DOBLE OPORTUNIDAD</span>
              <span
                class="headr "
                :class="{ right: isPremiumUser, center: !isPremiumUser }"
                :style="{
                  'justify-self': isPremiumUser ? 'right' : 'center',
                  'text-align': isPremiumUser ? 'right' : 'center',
                }"
              >
                Probabilidad
              </span>
              <div class="table-overlay-container" v-if="!isPremiumUser">
                <div class="table-overlay--desktop">
                  <div class="div-centyer-text">
                    <b>Solo para <br />Usuarios Premium</b> <br />($99 pesos al mes)
                  </div>
                </div>
              </div>
              <template v-for="(bet, i) in parlaysBets.double">
                <span
                  class="labl bet-info"
                  :class="{ 'max-prob': bet.isMaxProb && isPremiumUser }"
                  :key="'label ' + i"
                  >{{ bet.label }}</span
                >
                <span
                  class="stats bet-info"
                  :key="'stats ' + i"
                  :class="{
                    blurred: !isPremiumUser,
                    center: !isPremiumUser,
                    'max-prob': bet.isMaxProb && isPremiumUser,
                  }"
                  ><template v-if="!isPremiumUser">SKR</template><template v-else>{{ bet.stats }}</template></span
                >
              </template>
            </div>
            <div class="bet-stadistics__table">
              <span class="headr bold">MONEYLINE</span>
              <span class="headr " :class="{ right: isPremiumUser, center: !isPremiumUser }"></span>
              <!--              <div class="table-overlay-container" v-if="!isPremiumUser">-->
              <!--                <div class="table-overlay&#45;&#45;desktop">-->
              <!--                  <div class="div-centyer-text">-->
              <!--                    <b>Solo para <br />Usuarios Premium</b> <br />($99 pesos al mes)-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <template v-for="(bet, i) in parlaysBets.moneyLine">
                <span
                  class="labl bet-info"
                  :key="'label ' + i"
                  :class="{ 'max-prob': bet.isMaxProb && isPremiumUser }"
                  >{{ bet.label }}</span
                >
                <span
                  class="stats bet-info"
                  :key="'stats ' + i"
                  :class="{
                    blurred: !isPremiumUser,
                    center: !isPremiumUser,
                    'max-prob': bet.isMaxProb && isPremiumUser,
                  }"
                  ><template v-if="!isPremiumUser">SKR</template><template v-else>{{ bet.stats }}</template></span
                >
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="right-bet__btn" v-if="hasMainLeagueGames">
        <button @click="showMoreStats">
          <span>Ver más estadísticas</span>
          <img src="/icons/eye-icon.svg" alt="eye-icon" />
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import {
  widgetApostadoresApuestaDerechaJornadaYPartido,
  widgetApostadoresApuestaDerechaVerMasEstadisticas,
} from '@/utils/analytics';
import axios from 'axios';
import { connectSocket } from '@/utils/socketConnection';
import { BField } from 'buefy/dist/esm/field';
import { BSelect } from 'buefy/dist/esm/select';
import LastVSGames from '@/components/Sections/BetTool/LastVSGames';

const seasonType1Abbreviations = {
  PO1: 'CFI',
  PO2: 'CFV',
  PO3: 'SFI',
  PO4: 'SFV',
  PO5: 'FI',
  PO6: 'FV',
};

export default {
  name: 'RightBet',
  components: {
    BField,
    BSelect,
    LastVSGames,
  },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    /**
     * Seasons objects to fetch games
     * {Array<{id: number, type: number}>} seasons
     */
    seasons: {
      type: Array,
      default: () => [],
    },
    /**
     * All bets from all tournaments
     * {Array<{
     *   bet_id: number,
     *   game_id: number,
     *   date: string,
     *   season_id: number,
     *   tournament_id: number,
     *   home_team_id: number,
     *   home_team_name: string,
     *   home_team_logo: string,
     *   home_team_acronym: string,
     *   away_team_id: number,
     *   away_team_name: string,
     *   away_team_logo: string,
     *   away_team_acronym: string,
     *   bet_type_id: 1,
     *   bet_suggested: {
     *     home_team_logo: string,
     *     away_team_logo: string,
     *     additional_icon: string,
     *     suggestion: string,
     *   },
     *   prob_initial: number,
     *   odd: number,
     *   prob_actual: number,
     *   difference_probability: string,
     *   bet_risk: number,
     * }>}
     */
    totalBetsRightBet: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    fakeBetTypes: [{ id: 1, name: 'Resultado de partido' }],
    betType: 1,
    fullTournaments: {},
    currentTournament: null,
    currentMatch: null,
    gamesUrl: 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason?',
    generalUrl: 'https://jfxv8fqkn3.execute-api.us-west-2.amazonaws.com/prod/generalTable',
    parlayUrl: 'https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/parlays',
    tournamentsUrl: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
    parlays: null,
    gameInfo: null,
    tournaments: null,
    mainLeague: null,
    hasMainLeagueGames: true,
    areTablesVisible: true,
  }),
  watch: {
    displayWidth(newValue, oldValue) {
      if (newValue > 900) {
        this.areTablesVisible = true;
      } else if (oldValue > 900) {
        this.areTablesVisible = false;
      }
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapState(['displayWidth']),
    gameOptions() {
      if (this.currentTournament) {
        widgetApostadoresApuestaDerechaJornadaYPartido({
          matchday: this.currentTournament,
          game_id: this.currentMatch.game_id,
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
        return this.fullTournaments[this.currentTournament];
      }
      return [];
    },
    homeLastFiveGames() {
      if (this.currentMatch && this.currentMatch.home_team_lastgames) {
        const lastGames = JSON.parse(JSON.stringify(this.currentMatch.home_team_lastgames.last_five_games));
        const points = this.currentMatch.home_team_lastgames.points || 0;
        const pos =
          this.currentMatch.home_team_lastgames.games_played > 0
            ? this.currentMatch.home_team_lastgames.table_position
            : '-';
        lastGames.reverse();
        return { lastGames, points, pos };
      }
      return {};
    },
    visitingLastFiveGames() {
      if (this.currentMatch && this.currentMatch.visiting_team_lastgames) {
        const lastGames = JSON.parse(JSON.stringify(this.currentMatch.visiting_team_lastgames.last_five_games));
        const points = this.currentMatch.visiting_team_lastgames.points || 0;
        const pos =
          this.currentMatch.visiting_team_lastgames.games_played > 0
            ? this.currentMatch.visiting_team_lastgames.table_position
            : '-';
        lastGames.reverse();
        return { lastGames, points, pos };
      }
      return {};
    },
    parlaysBets() {
      if (this.currentMatch) {
        const homeTeamName = this.currentMatch.home_team.team_name;
        const visitingTeamName = this.currentMatch.visiting_team.team_name;
        let winHomeP = '';
        let winDrawHomeP = '';
        let drawP = '';
        let winVisitingP = '';
        let winDrawVisitingP = '';
        if (this.currentMatch.parlays.length) {
          winHomeP = this.currentMatch.parlays.find(p => p.bet_suggested.home_team_logo && p.bet_subtype_id === 1);
          winDrawHomeP = this.currentMatch.parlays.find(p => p.bet_suggested.home_team_logo && p.bet_subtype_id === 3);
          drawP = this.currentMatch.parlays.find(p => p.bet_subtype_id === 2);
          winVisitingP = this.currentMatch.parlays.find(p => !p.bet_suggested.home_team_logo && p.bet_subtype_id === 1);
          winDrawVisitingP = this.currentMatch.parlays.find(
            p => !p.bet_suggested.home_team_logo && p.bet_subtype_id === 3,
          );
        }
        return {
          double: [
            {
              label: `Gana / Empata ${visitingTeamName}`,
              stats: winDrawVisitingP ? winDrawVisitingP.prob_actual + '%' : '-',
              prob: winDrawVisitingP ? winDrawVisitingP.prob_actual : 0,
              team: visitingTeamName,
              action: 'Doble oportunidad',
            },
            {
              label: `Gana / Empata ${homeTeamName}`,
              stats: winDrawHomeP ? winDrawHomeP.prob_actual + '%' : '-',
              prob: winDrawHomeP ? winDrawHomeP.prob_actual : 0,
              team: homeTeamName,
              action: 'Doble oportunidad',
            },
          ],
          moneyLine: [
            {
              label: `Empata`,
              stats: drawP ? drawP.prob_actual + '%' : '-',
              prob: drawP ? drawP.prob_actual : 0,
              team: '',
              action: 'Empata',
            },
            {
              label: `Gana ${visitingTeamName}`,
              stats: winVisitingP ? winVisitingP.prob_actual + '%' : '-',
              prob: winVisitingP ? winVisitingP.prob_actual : 0,
              team: visitingTeamName,
              action: 'Gana',
            },
            {
              label: `Gana ${homeTeamName}`,
              stats: winHomeP ? winHomeP.prob_actual + '%' : '-',
              prob: winHomeP ? winHomeP.prob_actual : 0,
              team: homeTeamName,
              action: 'Gana',
            },
          ],
        };
      } else {
        return {
          double: [],
          moneyLine: [],
        };
      }
    },
    maxProb() {
      const allProb = [];
      let maxProbText = '';
      this.parlaysBets.double.forEach(bet => {
        bet.isMaxProb = false;
        allProb.push(bet);
      });
      this.parlaysBets.moneyLine.forEach(bet => {
        bet.isMaxProb = false;
        allProb.push(bet);
      });
      allProb.sort((a, b) => {
        return b.prob - a.prob;
      });
      if (allProb.length) {
        const maxProb = allProb[0];
        maxProbText = `${maxProb.action} ${maxProb.team}`;
        maxProb.isMaxProb = true;
      }

      return maxProbText;
    },
    tournamentName() {
      const season = this.availableSeasons.find(season => season.type === 0);
      return (season && season.name) || '-';
    },
    availableSeasons() {
      // if (this.seasons.length) {
      //   return this.seasons;
      // } else if (this.tournaments) {
      const availableSeasons = [];
      if (this.tournaments) {
        const regularSeason = this.mainLeague.seasons[0];
        availableSeasons.push({ id: regularSeason.id, type: regularSeason.type, name: regularSeason.name });
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          availableSeasons.push(
            ...regularSeason.related_seasons.map(relatedSeason => ({ id: relatedSeason.id, type: relatedSeason.type })),
          );
        }
      }
      return availableSeasons;
    },
  },
  methods: {
    ...mapMutations('nextGeneral', ['prepareCleanAllStatistics']),
    ...mapMutations('general', ['setPreviewSectionAnalytics']),
    getTeamLogo(match, teamType) {
      const prop = `${teamType}_team_logo`;
      const parlay = match.parlays[0];
      if (parlay.provisionalTeamLogo) {
        return `${parlay.provisionalTeamLogo}/${parlay[prop]}`;
      } else if (parlay[prop]) {
        return `https://az755631.vo.msecnd.net/teams-80/${parlay[prop]}`;
      }
      return '/assets/icons/icn-foco-rojo.svg';
    },
    /**
     * @author Cristhian Gómez
     * @description Push route to more stats
     * @return {void}
     */
    showMoreStats() {
      if (this.currentMatch) {
        this.setPreviewSectionAnalytics('RightBet');
        widgetApostadoresApuestaDerechaVerMasEstadisticas({
          game_id: this.currentMatch.game_id,
          season_id: this.currentMatch.seasonId,
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
        this.$router.push(
          `/matches/${this.currentMatch.game_id}?currentSeason=${this.currentMatch.seasonId}&is_iframe=true`,
        );
      }
    },
    /**
     * @author Cristhian Gómez
     * @param {Array<Object>} seasons - seasons object
     * @param {Array<Object>} seasonsAndGames - Games array
     * @param {Array<Object>} seasonsAndTeams - Teams array
     * @description Creates fulltournament object witch contain entire info for the component
     * @return {void}
     */
    async createFullTournamentScheme(seasons, seasonsAndGames, seasonsAndTeams) {
      try {
        this.fullTournaments = {};
        if (this.getPremiumAccount) connectSocket();
        await this.prepareCleanAllStatistics();
        seasonsAndGames.forEach(seasonAndGames => {
          const { type } = seasons.find(season => season.id === seasonAndGames.season_id);
          seasonAndGames.type = type;
        });
        //group only nextAvailableGames and set them info aobut teams and seasons
        const nextAvailableGames = seasonsAndGames.reduce((accumulator, currentSeason) => {
          const { teams } = seasonsAndTeams.find(sandt => sandt.season_id === currentSeason.season_id);
          const nowInMilliseconds = new Date().valueOf();
          currentSeason.games.forEach(game => {
            const hasNotPlayedYet = new Date(game.date_time_utc).valueOf() > nowInMilliseconds;
            if (hasNotPlayedYet && game.game_status === 4 && game.is_live === 0) {
              game.home_team = teams.find(team => team.team_id === game.home_team);
              game.visiting_team = teams.find(team => team.team_id === game.visiting_team);
              game.label = `${game.home_team.team_name} vs ${game.visiting_team.team_name}`;
              game.seasonId = currentSeason.season_id;
              game.parlays = this.totalBetsRightBet.filter(bet => bet.game_id === game.game_id);
              // game.parlays = this.parlays.tournaments.reduce((acc, currentVal) => {
              //   const bets = currentVal.bets.filter(b => b.game_id === game.game_id);
              //   return bets.length ? [...acc, ...bets] : acc;
              // }, []);
              game.home_team_lastgames = this.gameInfo.find(team => team.team_id === game.home_team.team_id);
              game.visiting_team_lastgames = this.gameInfo.find(team => team.team_id === game.visiting_team.team_id);
              if (game.date) {
                game.displayDate = game.date
                  .split('-')
                  .reverse()
                  .join('-');
              }
              // game.displayDate = format(new Date(), 'dd-M-yyyy');
              //validate that the games has at least one parlay
              if (game.parlays.length) {
                if (!accumulator[game.matchday_abbreviation]) {
                  accumulator[game.matchday_abbreviation] = { games: [] };
                  accumulator[game.matchday_abbreviation].type = currentSeason.type;
                  accumulator[game.matchday_abbreviation].games.push(game);
                } else {
                  accumulator[game.matchday_abbreviation].games.push(game);
                }
              }
            }
          });
          return accumulator;
        }, {});
        this.fullTournaments = this.changeToLocalNames(nextAvailableGames);
        //selectDefault values
        this.currentTournament = Object.keys(this.fullTournaments).find(k => k);
        this.selectDefaultMatch();
      } catch (e) {
        throw new Error(e);
      }
    },
    /**
     * @author Cristhian Gómez
     * @param {Array<number>} seasons - seasons ids
     * @param {number} retries - Times to retry the request
     * @param {String} createdUrl - Url to make the request
     * @return {Promise<{seasonsAndGames: *, seasonsAndTeams: *}|*|undefined>}
     */
    async fetchSeasonsGamesAndTeams(seasons, retries, createdUrl = '') {
      let gamesUrl = createdUrl;
      try {
        if (!gamesUrl) {
          gamesUrl = this.gamesUrl;
          seasons.forEach((seasonId, index) => {
            const prefix = index === 0 ? '' : '&';
            gamesUrl += `${prefix}seasons=${seasonId}`;
          });
        }
        const {
          data: { data, teams },
        } = await axios.get(gamesUrl);
        return { seasonsAndGames: data, seasonsAndTeams: teams };
      } catch (e) {
        if (retries > 0) {
          return this.fetchSeasonsGamesAndTeams(seasons, retries - 1, gamesUrl);
        }
        throw new Error(e);
      }
    },
    /**
     * @author Cristhian Gómez
     * @param {number} retries - Times to retry the request
     * @param {Object=} alternativeSeason
     * @return {Promise<Array>} last5gamesInfo
     */
    async fetchGeneralTable(retries, alternativeSeason = null) {
      // const seasonApi = season == null ? 1325 : this.seasons;
      try {
        const season = alternativeSeason || this.availableSeasons.find(s => s.type === 0);
        let generalUrl = this.generalUrl;
        if (season) {
          generalUrl = `${this.generalUrl}/${season.id}`;
        }
        const { data } = await axios.get(generalUrl);
        if (data && data.data) {
          this.gameInfo = data.data;
        }
        return { response: data };
      } catch (e) {
        if (retries > 0) {
          return this.fetchGeneralTable(retries - 1, alternativeSeason);
        }
        throw new Error(e);
      }
    },
    /**
     * @author Cristhian Gómez
     * @param {number} retries - Times to retry the request
     * @return {Promise<Array>} last5gamesInfo
     */
    async fetchTournaments(retries) {
      // const seasonApi = season == null ? 1325 : this.seasons;
      try {
        let tournamentsUrl = this.tournamentsUrl;
        const { data } = await axios.get(tournamentsUrl);
        if (data) {
          this.tournaments = data;
          this.mainLeague = this.tournaments.find(t => t.tournament.id === 1);
        }
      } catch (e) {
        if (retries > 0) {
          return this.fetchGeneralTable(retries - 1);
        }
        throw new Error(e);
      }
    },
    /**
     * @author Cristhian Gómez
     * @param {number} retries - Times to retry the request
     * @return {Promise<Array>} parlays
     */
    async fetchParlay(retries) {
      // const seasonApi = season == null ? 1325 : this.seasons;
      const parlayUrl = this.parlayUrl;
      try {
        const { data } = await axios.get(parlayUrl);
        if (data) {
          this.parlays = data;
        }
        return { response: this.parlays };
      } catch (e) {
        if (retries > 0) {
          return this.fetchParlay(retries - 1);
        }
        throw new Error(e);
      }
    },
    /**
     * @author Cristhian Gómez
     * @param {Object} nextAvailableGames - tournament object
     * @return {Promise<Array>} returns tournament object with new season local names
     */
    changeToLocalNames(nextAvailableGames) {
      const newSeasons = {};
      for (const [k, v] of Object.entries(nextAvailableGames)) {
        const sortGames = v.games.sort((a, b) => new Date(a.date_time_utc) - new Date(b.date_time_utc));
        if (v.type === 0) {
          newSeasons[Number(k.replace('M', '')).toString()] = sortGames;
        } else if (v.type === 2) {
          newSeasons[`R${Number(k.replace('PO', ''))}`] = sortGames;
        } else if (v.type === 1) {
          newSeasons[seasonType1Abbreviations[k]] = sortGames;
        }
      }
      return newSeasons;
    },
    /**
     * @author Cristhian Gómez
     * @description set default first option when tournament is selected
     * @return {void} parlays
     */
    selectDefaultMatch() {
      if (this.currentTournament && this.fullTournaments[this.currentTournament].length) {
        this.currentMatch = this.fullTournaments[this.currentTournament][0];
      } else {
        this.currentMatch = null;
      }
    },

    getHasMainLeagueGames(leagueGames) {
      const nowInMilliseconds = new Date().valueOf();
      const availableGames = leagueGames.filter(game => {
        const hasNotPlayedYet = new Date(game.date_time_utc).valueOf() > nowInMilliseconds;
        return hasNotPlayedYet && game.game_status === 4 && game.is_live === 0;
      });
      return availableGames.length > 0;
    },
  },
  async created() {
    try {
      // if (!this.seasons.length) {
      let provisionalSeason = null;
      await this.fetchTournaments(3);
      let seasonGamesAndTeams = await this.fetchSeasonsGamesAndTeams(
        this.availableSeasons.map(season => season.id),
        3,
      );
      this.hasMainLeagueGames = this.getHasMainLeagueGames(seasonGamesAndTeams.seasonsAndGames[0].games);
      if (!this.hasMainLeagueGames) {
        provisionalSeason = this.mainLeague.provisional_season;
        seasonGamesAndTeams = await this.fetchSeasonsGamesAndTeams([provisionalSeason.id], 3);
      }
      await this.fetchGeneralTable(3, provisionalSeason);
      // }
      // const [{ seasonsAndGames, seasonsAndTeams }] = await Promise.all([
      //   this.fetchSeasonsGamesAndTeams(
      //     this.availableSeasons.map(season => season.id),
      //     3,
      //   ),
      //   this.fetchGeneralTable(3),
      //   // this.fetchParlay(3),
      // ]);
      this.createFullTournamentScheme(
        provisionalSeason ? [provisionalSeason] : this.availableSeasons,
        seasonGamesAndTeams.seasonsAndGames,
        seasonGamesAndTeams.seasonsAndTeams,
      );
      // this.createFullTournamentScheme(this.seasons, seasonsAndGames, seasonsAndTeams);
    } catch (e) {
      throw new Error(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.div-centyer-text {
  width: 100%;
  margin-top: 44%;
  display: table-cell;
  vertical-align: middle;
  margin: 0 auto;
  position: relative;
}
.center {
  text-align: center !important;
}
.right-bet {
  display: flex;
  flex-flow: column;
  align-items: center;
  &__header {
    width: 100%;
    max-width: 600px;
  }
  &__spacer {
    width: 100%;
    width: calc(100% - 112px);
    max-width: 1047px;
    margin: 10px 56px;
    border-top: dotted 1px #979797;
  }
  &__teams {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: center;
    margin-bottom: 12px;
  }
  &__info {
    width: calc(100% - 30px);
    //margin-left: 20px;
    //margin-right: 20px;
    max-width: 1254px;

    padding: 31px 50px;
    border-radius: 12px 12px 0px 0px;
    background-color: rgba($color: #d8d8d8, $alpha: 0.1);
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    grid-template-areas:
      'games spacer stats'
      'games spacer stats';
    @media screen and (max-width: 900px) {
      padding: 31px 0;
      grid-template-columns: 1fr;
      grid-template-areas:
        'stats'
        'expand'
        'games';
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 10px;
    }

    &__expand {
      display: none;

      @media screen and (max-width: 900px) {
        justify-self: center;
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
        font-family: Avenir-Demi, sans-serif;
        font-size: 1.5rem;

        &__text {
          @media screen and (max-width: 354px) {
            font-size: 1.1rem;
          }
        }

        &__btn {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          border: solid 2px #dadada;
          margin: 1rem;
        }
      }
    }

    .spacer {
      width: 1px;
      height: 480px;
      border: dotted 0.5px rgba($color: #979797, $alpha: 0.5);
      position: relative;
      //top: 20px;
      margin: 0 20px;
      grid-area: spacer;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .bet-stadistics {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      grid-area: stats;
    }

    &__last-games-container {
      display: flex;
      flex-direction: column;
      grid-area: games;
      align-self: center;

      @media screen and (max-width: 900px) {
        width: 60%;
        justify-self: center;
      }

      @media screen and (max-width: 814px) {
        width: 80%;
        justify-self: center;
      }

      @media screen and (max-width: 650px) {
        width: 100%;
        justify-self: center;
      }
    }
  }
  &__btn {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1254px;
    flex-wrap: wrap;
    border-radius: 0px 0px 12px 12px;
    background-color: rgba($color: #d8d8d8, $alpha: 0.1);
    display: flex;
    justify-content: center;
    padding-bottom: 34px;
    @media screen and (max-width: 375px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    button {
      width: 320px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
      background-color: #6eb90f;
      outline: none;
      cursor: pointer;
      border: none;
      font-family: Circular-Std-Book;
      font-size: 20px;
      color: #ffffff;
      transition: all 0.65s ease;
      @media screen and (max-width: 375px) {
        width: 100%;
      }
      span {
        padding-right: 12px;
      }

      &:hover {
        background-color: #5f9617;
      }
    }
  }
}
.bet-stadistics__container {
  margin-top: 1.3rem;
}
.header {
  display: grid;
  grid-template-columns: 190px minmax(348px, 1fr);
  column-gap: 20px;
  align-items: center;
  padding-top: 39px;
  margin-left: 20px;

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
  &__title {
    font-family: Circular-Std-Book;
    font-size: 17px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -0.55px;
    align-self: flex-end;
    color: #272727;
  }
  &__selects {
    display: grid;
    grid-template-columns: 125px minmax(150px, 1fr);

    & > div {
      max-width: 100%;
    }
    @media screen and (max-width: 980px) {
      justify-content: center;
      grid-template-columns: 160px 160px;
      column-gap: 0;
      justify-items: flex-end;
    }
    @media screen and (max-width: 768px) {
      justify-items: center;
      grid-template-columns: 125px 160px;
    }
  }
}
.teams {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 72px 70px 100px 70px 72px;
  align-items: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 68px 0.4fr 1fr 0.4fr 68px;
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: 50px 0.4fr 1fr 0.4fr 50px;
  }

  &__img {
    width: 100%;
  }
  &__name {
    font-family: Circular-Std-Bold;
    font-size: 26px;
    letter-spacing: 0.14px;
    color: #333333;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
  &__vs {
    text-align: center;
    font-family: Circular-Std-Bold;
    font-size: 37.6px;
    font-weight: bold;
    letter-spacing: -0.19px;
    color: #333333;
  }
}
.date {
  font-size: 20px;
  font-family: Circular-Std-Book;
  color: #272727;
  &__journey {
    font-family: Circular-Std-Bold;
    padding-right: 7px;
  }
}
$blur: 10px;

.blurred {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;
}
.table-overlay-container {
  overflow: visible;
  height: 220%;
  right: -3%;
  z-index: 9;
  position: absolute;

  @media screen and (max-width: 768px) {
    right: -3%;
  }
  @media screen and (max-width: 1550px) {
    right: 0%;
  }
  @media screen and (max-width: 303px) {
    right: 0%;
    width: 50%;
  }
}

@mixin table-overlay {
  width: 90%;
  height: 84%;
  margin: 0 auto;
  margin-top: 35px;
  font-family: 'Circular-Std-Medium';
  border: dotted 2px #5c5c5c;
  color: #3e3e3e;
  border-radius: 8px;
  display: table;
  text-align: center;
  position: relative;
}

@mixin font-circular-bold {
  font-family: 'Circular-Std-Black';
  color: #272727f5;
}

.table-overlay {
  &--mobile {
    @include table-overlay;
    font-size: clamp(0.5rem, 1.5rem, 3vw);
    & > b {
      @include font-circular-bold;
    }
  }
  &--desktop {
    @include table-overlay;
    @media screen and (min-width: 1280px) {
      font-size: clamp(0.5rem, 1.3rem, 1.2vw);
      & > b {
        @include font-circular-bold;
      }
    }
  }

  &--mobile,
  &--desktop {
    transition: width 50ms;
    transition-timing-function: linear;
  }
}
.last-games {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 1.8rem;
  &__title {
    width: 100%;
    border-radius: 4.7px;
    background-color: #e6e5e6;
    padding: 5px 0;
    font-size: 0.85rem;
    margin-bottom: 5px !important;
    font-family: Avenir-Demi, sans-serif;
  }
  &__rows,
  &__header {
    display: grid; //258px
    grid-template-columns: minmax(30px, 56px) minmax(200px, 228px) minmax(30px, 57px) minmax(30px, 47px);
    align-items: center;
    column-gap: 20px;
    @media screen and (max-width: 900px) {
      column-gap: 0;
    }
    @media screen and (max-width: 768px) {
      max-width: 380px;
      width: 100%;
      grid-template-columns: 48px 1fr minmax(37px, 57px) minmax(19px, 47px);
      column-gap: 10px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 42px 1fr 32px 16px;
      column-gap: 8px;
    }
  }
  &__header {
    height: 30px;
    font-family: Circular-Std-Book;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
    p {
      margin-bottom: 1em;
    }
  }
  &__rows {
    margin-bottom: 10px;

    .team-row {
      display: grid;
      grid-template-columns: repeat(5, 32px);
      align-items: center;
      justify-content: center;
      column-gap: 12px;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(5, 24px);
        column-gap: 5px;
      }
    }
    .team-row__pts,
    .team-row__pos {
      font-family: Avenir-Pro-Bold;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -1.12px;
      text-align: center;
      color: #545454;
      margin: 0;
      line-height: 1;
      margin-top: 5px;
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
      @media screen and (max-width: 576px) {
        font-size: 18px;
      }
    }
    .team-row__item {
      height: 32px;
      color: #ffffff;
      font-family: Avenir-Demi;
      font-size: 19.2px;
      position: relative;
      letter-spacing: -0.72px;
      text-align: center;
      @media screen and (max-width: 768px) {
        height: 24px;
        font-size: 14px;
      }

      &.win,
      &.draw,
      &.lose {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        width: 100%;
        span {
          margin-top: 2px;
        }
      }
      &.win {
        background-color: #73d44e;
      }
      &.draw {
        background-color: #ababab;
      }
      &.lose {
        background-color: #eb2424;
      }
    }
  }
}
.bet-stadistics {
  display: grid;
  margin-left: 50px;

  &__title {
    font-size: 1.4rem;
    font-family: Avenir-Pro-Bold, sans-serif;
  }

  &__subtitle {
    padding: 0.24rem 1.25rem;
    font-size: 1.3rem;
    font-family: Avenir-Demi, sans-serif;
    border-radius: 6px;
    background-color: #ffff03;
  }
  //min-width: 370px;
  @media screen and (max-width: 980px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
  }

  &__select {
    text-align: left;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  &__table {
    margin-top: 1.5rem;
    display: grid;
    position: relative;
    grid-template-columns: minmax(158px, 1fr) 144px;
    column-gap: 0;
    @media screen and (max-width: 768px) {
      //column-gap: 10px;
    }
    @media screen and (max-width: 375px) {
      grid-template-columns: 0.8fr 0.8fr;
      //column-gap: 4px;
    }
  }
  .headr,
  .labl {
    font-size: 20px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (max-width: 375px) {
      font-size: 14px;
    }
  }
  .headr {
    font-family: Circular-Std-Book;
    font-weight: 400;
    color: #272727;
    text-align: left;
    margin-bottom: 0.6rem;
    &.bold {
      font-family: Avenir-Pro-Bold, sans-serif;
    }
    &.right {
      text-align: center;
      @media screen and (max-width: 768px) {
        text-align: right;
      }
    }
  }
  .labl {
    text-align: left;
    font-family: Avenir-Pro-Medium, sans-serif;
    line-height: 1.85;
    letter-spacing: -0.75px;
    color: #545454;
    width: 100%;
  }
  .stats {
    text-align: right;
    font-family: Avenir-Pro-Bold;
    font-size: 24px;
    line-height: 1.54;
    letter-spacing: -0.9px;
    color: #545454;
    justify-self: right;
    width: 100%;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      text-align: right;
    }
  }
  .control {
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}
.bet-info {
  padding-right: 20px;
}
.max-prob {
  background-color: #fbfbc9;
}
</style>
